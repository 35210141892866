// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@hotwired/turbo-rails")
require("@rails/activestorage").start()
require("channels")
require("trix")
require("@rails/actiontext")

require("chartkick/chart.js")
require("jquery")
require("jquery-ui")
require("jquery-ui/ui/widgets/datepicker")
require("bootstrap")
require("flickity")
require("moment")
require("flatpickr")
require("video.js")
require("amazon-ivs-player")
require("bootstrap-pincode-input")
require("packs/pincode_init")
require("packs/video_analytics")
require("packs/toggle_sticky_position_js")
require("packs/js_carousel_slides")
require("packs/videojs_init")
require("packs/flickity_init")
require("packs/searchable_dropdown")
require("packs/jquery.mask")
require("packs/masks")
require("packs/events_scripts")
require("packs/scrolling_snippet")
require("packs/singledatepicker")
require("packs/tooltip_init")
require("packs/charts_in_tabs")
require("packs/accordion_item_open")
require("packs/flatpickr_range")
require("packs/flatpickr_single")
require("packs/form_fields_visibility")
require("packs/hide_arrow_on_scroll")
require("packs/embed_quicksight_dashboard")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "controllers"
